import Vue from 'vue'
import App from './App.vue'
//apollo
import { createProvider } from 'vue-apollo';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import {IntrospectionFragmentMatcher} from 'apollo-cache-inmemory';
import VueApollo, { ApolloProvider } from 'vue-apollo';
import router from './router'

import introspectionQueryResultData from './fragmentTypes.json';
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

Vue.config.productionTip = false

//apollo
const httpLink = new HttpLink({
  uri: process.env.VUE_APP_APIURL
});

const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache({fragmentMatcher}),
  connectToDevTools: true
});

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});


new Vue({
  apolloProvider,
  router,
  render: h => h(App),
}).$mount('#app')
