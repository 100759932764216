<template>
  <div class="news">
    <div class="mainmessage" v-html="newsItem"></div>
    <svg width="37.414" height="37.414" viewBox="0 0 37.414 37.414" id="closenews" @click="closeNews()">
      <g id="Group_41" data-name="Group 41" transform="translate(0.707 0.707)">
        <line id="Line_7" data-name="Line 7" x2="36" y2="36" fill="none" stroke="#000" stroke-width="2"/>
        <line id="Line_8" data-name="Line 8" y1="36" x2="36" fill="none" stroke="#000" stroke-width="2"/>
      </g>
    </svg>
  </div>
</template>

<script>

export default {
  name: 'News',
  props: {
    newsItem: {
      required: true
    }
  },
  methods: {
    closeNews() {
      this.$parent.showNews = false
    }
  }
}
</script>

<style scoped>
.mainmessage { font-size: 2.5rem; padding: 0.5rem; }
.news {
  background-color: #F8C283;
  min-height: 100%;
  /*position: absolute;
  top: 0;
  padding-right:2.5rem;*/
}
>>> p {
  margin-top: 0;
}
>>> a { text-decoration: underline !important; }
@media (max-aspect-ratio: 12/10) {
  .mainmessage { font-size: 1.2rem; }
}
#closenews {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  display: none;
}
</style>
