import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import Webcam from '@/components/Webcam'
import Page from '@/components/Page'
import Repairform from '@/components/Repairform'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: { title: 'Baugenossenschaft Wiedikon' }
    },
    {
      path: '/bau',
      name: 'Webcam',
      component: Webcam,
      meta: { title: 'Webcam Kolonie 3' }
    },
    {
      path: '/reparaturen',
      name: 'Reparaturen',
      component: Repairform,
      meta: { title: 'Reparaturen' }
    },
    {
      //this is a redirect to a google drive folder
      path: '/architektenplaene',
      name: 'Plans',
      component: Page,
      meta: { title: 'Architektenpläne' }
    },
    {
      path: '/:slug',
      name: 'SinglePage',
      component: Page,
    }
  ]
})
