<template>
  <keep-alive>
    <!--not being kept alive-->
    <div
      class="home-and-news"
      id="home-and-news"
    >
      <div
        class="home"
        :class="classObj"
        ref="homecontainer"
        id="homecontainer"
      >
        <Wlogo id="second" />
        <Wlogo id="third" />
        <!--<Wlogo id="first" :imgInfo="(homeData && homeData.homeImgs && homeData.homeImgs.length > 0) ? homeData.homeImgs[0] : null" />-->
        <Wlogo
          id="first"
          :imgInfo="homeImg"
        />
        <!--<Wlogo id="fourth"
          v-if="homeData && homeData.homeImgs && homeData.homeImgs.length > 1"
          :imgInfo="(homeData && homeData.homeImgs && homeData.homeImgs.length > 1) ? homeData.homeImgs[1] : null" />-->
      </div>
    </div>
    <!--end home and news-->
  </keep-alive>
</template>

<script>
import Wlogo from "./Wlogo.vue";
import News from "./News.vue";

export default {
  name: "Home",
  components: {
    Wlogo,
    News,
  },
  props: {
    homeData: {
      required: true,
    },
    showNews: {
      required: false,
    },
  },
  data: function () {
    return {
      classObj: [],
      //hard coded home img to be faster.
      homeImg: {
        filename: "BIC8073-LORES.jpg",
        focalPoint: [0.5, 0.5],
        mimeType: "image/jpeg",
        title: "BIC8073 LORES",
        url: "https://admin.bgwiedikon.ch/assets/uploads/_large/BIC8073-LORES.jpg",
      },
      //showNews: false,
    };
  },
  /*watch: {
    $route(to, from) {
      alert()
      if(to.name == 'Home') {
        this.classList()
        let that = this
        //hide news if not home.
        setTimeout(function(){
          //show news if home.
          alert()
            that.showNews = true
        }, 300);
      } else {
        this.showNews = false
      }
    }
  },*/
  methods: {
    classList() {
      this.classObj = [];
      setTimeout(
        function () {
          this.classObj.push("first-animation");
        }.bind(this),
        1200
      );
      setTimeout(
        function () {
          this.classObj.push("second-animation");
        }.bind(this),
        2000
      );
      /*if(this.homeData.homeImgs && this.homeData.homeImgs.length > 1) {
        setTimeout(function(){ this.classObj.push('third-animation'); }.bind(this), 2400);
      }*/
    },
  },
  mounted() {
    this.classList();
  },
};
</script>

<style scoped>
.home-and-news {
  overflow: scroll;
  height: 100%;
  //scroll-snap-type: y mandatory;
  position: relative;
}
.home {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  scroll-snap-align: start;
}
#first,
#second,
#third,
#fourth {
  transition: all 1s;
  height: 0;
  height: 0;
}
#first {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.home.first-animation #first {
  height: 60%;
  top: 40%;
}
.home.first-animation #second {
  height: 40%;
  /*position: absolute;*/
  /*position absolute led to 0 height in safari, no idea why*/
  left: 0;
  top: 0;
}
.home.first-animation #third {
  height: calc(35% - 6vw);
}
.home.second-animation #second {
  width: 40%;
}
#third {
  left: 100%;
  position: absolute;
}
.home.second-animation #third {
  width: 20%;
  height: 30%;
  left: 37%; /*we will need to calculate exactly*/
  top: 10%;
}

/*#fourth { right: -100%; position: absolute; height: calc(100% - 6vw - 7vw); top: 7vw; }
.home.third-animation #first{
  width: 60%;
}
.home.third-animation #fourth{
  width: 50%;
  top: 7vw;
  right: 0;
}
@media (max-aspect-ratio: 12/10) {
  #fourth { height: 0; width: 100%; position: static; }
  .home.third-animation #first{
    width: 100%;
  }
  .home.third-animation #second{
  }
  .home.third-animation #third{
    top: 2vw;
  }
  .home.third-animation #fourth{
    width: 100%;
  }
  .home.third-animation #fourth{
    width: 100%;
    height: 150%;
  }
}*/
@media (max-aspect-ratio: 12/10) {
  .singlepage #second,
  .singlepage #third {
    display: none;
  }
  .singlepage #first >>> .background {
    background-image: none !important;
  }
  .menu .home {
    border-bottom: 3px solid #000;
    height: 100%;
    overflow-x: hidden;
  }
  .singlepage .menu .home {
    height: calc(100% - 3px);
  }
}
.singlepage .home.first-animation #first {
  top: 0;
  height: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
#home-and-news {
  overflow-x: hidden;
}
</style>
